import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import { connect } from "react-redux";
import CartBannerComponent from "../../Components/ProdectBanner/CartBannerComponent";
import { cartOperations } from "../../state/ducs/cart";
import { numberWithCommas } from "../../utils";
import { useNavigate } from "react-router";
import "./index.css";
import Swal from 'sweetalert2'


const CustomToggle = ({ children, passedStep, eventKey, callback, unref }) => {
  const decoratedOnClick = useAccordionButton(eventKey, () => {
    console.log("totally custom!");
    callback && callback(eventKey);
  });

  return (
    <div className="">
      <button
        className="collapseButton"
        type="button"
        ref={unref}
        style={{
          backgroundColor: "transparent",
          border: "none",
          outline: "none",
          width: "100%",
          height: "100%",
          textAlign: "left",
          padding: "20px",
          display: "flex",
          alignItems: "center",
        }}
        onClick={decoratedOnClick}
        disabled={eventKey > passedStep}
      >
        {children}
        {eventKey <= passedStep && <i className="fa fa-chevron-down mx-2"></i>}
      </button>
    </div>
  );
};

const CardComponent = (props) => {
  return (
    <Card>
      <Card.Header>
        <CustomToggle
          unref={props.unref}
          passedStep={props.passedStep}
          eventKey={props.eventKey}
        >
          <h5>{props.heading}</h5>
        </CustomToggle>
      </Card.Header>
      <Accordion.Collapse
        // appear={props.passedStep == props.eventKey}
        eventKey={props.eventKey}
      >
        <Card.Body>{props.children}</Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

const CheckoutPage = ({ cartItems, clearCart, userInfo, isAuthenticated }) => {
  const navigate = useNavigate();

  console.log("cartItems", cartItems);
  const [passedStep, setPassedStep] = useState(0);

  const [userName, setUserName] = useState({
    firstName: userInfo.name.split(" ")[0] || "",
    lastName: userInfo.name.split(" ")[1] || "",
  });
  const [email, setEmail] = useState(userInfo.email || "");
  const [password, setPassword] = useState(userInfo.password || "");

  let subtotalPrice = 0;
  for (let index = 0; index < cartItems.length; index++) {
    const element = cartItems[index];
    subtotalPrice += parseInt(element.price) * element.quantity;
  }

  const onOrderSubmitHandler = () => {


    // const orderObj = {
    //   userName: userName.firstName + userName.lastName,
    //   email: email,
    //   password: password,
    //   total: subtotalPrice + 1000, //1000 flat rate
    //   cartItems: cartItems,
    //   pay_amount: "float",
    //   paymentMethod: "string",
    //   customer_email: "email",
    //   customer_name: "string",
    //   customer_phone: "customer_phone",



    // };

    const orderObj2 = {
      user_id: userInfo.id,
      ordered_products: cartItems.map(item => ({
        id: item.id,
        name: item.name,
        price: item.price,
        totalQuantity: item.quantity,
        totalPrice: item.price * item.quantity,
      })),

      pay_amount: subtotalPrice,
      paymentMethod: "COD",
      customer_email: userInfo.email,
      customer_name: userInfo.name,
      customer_phone: userInfo.phone,
      customer_address: userInfo.address,
      customer_country: userInfo.country,
      customer_city: userInfo.city,
      customer_zip: userInfo.zip,
      vendor_name: cartItems[0].vendor_name

    }

    const Ids = cartItems.filter(item => item.cart_id).map(item => item.cart_id);


    axios
      .post("https://ap.japanidokan.com/api/order/store", orderObj2)
      .then(function (response) {

        console.log(response);
        if (response.status === 200) {
          // alert("Order Placed Successfully");
          // clearCart();
          // navigate("/myAccounts");




          if (Ids.length > 0) {

            axios.delete('https://ap.japanidokan.com/api/cart-items', {
              data: {
                ids: Ids, // Pass the array of cart item ids to be deleted
              },
            })
              .then(function (response) {
                console.log(response);
                if (response.status === 200) {
                  clearCart();
                  Swal.fire({
                    icon: 'success',
                    title: 'Order Placed Successfully!',
                    text: 'Your order has been placed successfully.',
                  })
                  navigate("/myAccounts");
                  // navigate("/");
                }
              })
              .catch(function (error) {
                console.log(error);
              });

          } else {
            clearCart();
            navigate("/myAccounts");
            Swal.fire({
              icon: 'success',
              title: 'Order Placed Successfully!',
              text: 'Your order has been placed successfully.',
            })
            // navigate("/");
          }



        } else if (response.status === 400) {
          alert(response.message);
          clearCart();
        }
      })
      .catch(function (error) {
        console.log(error);
        alert("Order Failure");
      });
  };

  const ref0 = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  // const ref5 = useRef(null);

  useEffect(() => {
    if (passedStep === 1) {
      ref1.current.click();
    } else if (passedStep === 2) {
      ref2.current.click();
    } else if (passedStep === 3) {
      ref3.current.click();
      // } else if (passedStep === 4) {
    } else {

      if (isAuthenticated) {
        //ref4 not needed
      } else {
        ref4.current.click();

      }
    }
    // else {
    //   ref5.current.click();
    // }
  }, [passedStep]);

  const onFirstContinueClickHandler = (num) => {
    setPassedStep(parseInt(num) + 1);
  };

  let accordArray = [
    {
      num: "0",
      title: "Step 1: Checkout Options",
      innerComponent: (
        <div className="row">
          <div className="col-md-6">
            <h1>New Customer</h1>
            <p>Checkout Options:</p>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault1"
                id="flexRadioDefault1"
              />
              <label class="form-check-label" for="flexRadioDefault1">
                Register Account
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault2"
                id="flexRadioDefault2"
                checked
              />
              <label class="form-check-label" for="flexRadioDefault2">
                Guest Checkout
              </label>
            </div>
            <p>
              By creating an account you will be able to shop faster, be up to
              date on an order's status, and keep track of the orders you have
              previously made.
            </p>
            <button
              onClick={() => onFirstContinueClickHandler("0")}
              type="button"
              class="btn btn-secondary"
            >
              CONTINUE{" "}
            </button>
          </div>
          <div className="col-md-6">
            <h1>Returning Customer</h1>
            <p>I am a returning customer</p>
            <form>
              <div class="form-group">
                <label for="exampleInputEmail1">Email address</label>
                <input
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                />
                <small id="emailHelp" class="form-text text-muted">
                  We'll never share your email with anyone else.
                </small>
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1">Password</label>
                <input
                  type="password"
                  class="form-control"
                  id="exampleInputPassword1"
                  placeholder="Password"
                />
              </div>
              <div class="form-group form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="exampleCheck1"
                />
                <label class="form-check-label" for="exampleCheck1">
                  Check me out
                </label>
              </div>
              <button type="submit" class="btn btn-primary">
                LogIn
              </button>
            </form>
          </div>
        </div>
      ),
    },

    {
      num: "1",
      title: "Step 2: Billing Details",
      innerComponent: (
        <div className="row">
          <div className="col-md-6">
            <form action="">
              <fieldset id="accounts">
                <legend className="legender">Your Personal Details</legend>
                <div className="form-group ">
                  <label htmlFor="input-first Name" className="col-sm-2 ">
                    First Name
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      name="firstname"
                      placeholder="First Name"
                      id="input-firstname"
                      className="from-control"
                      value={userName.firstName}
                      onChange={(e) =>
                        setUserName({ ...userName, firstName: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="form-group ">
                  <label htmlFor="input-first Name" className="col-sm-2 ">
                    Last Name
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      name="lastname"
                      placeholder="Last Name"
                      id="input-firstname"
                      className="from-control"
                      value={userName.lastName}
                      onChange={(e) =>
                        setUserName({ ...userName, lastName: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="form-group ">
                  <label htmlFor="input-first Name" className="col-sm-2 ">
                    E-Mail
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      name="email"
                      placeholder="E-Mail"
                      id="input-email"
                      className="from-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group ">
                  <label htmlFor="input-first Name" className="col-sm-2 ">
                    Telephone
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      name="firstname"
                      placeholder="Telephone"
                      id="input-telephone"
                      className="from-control"
                      value={userInfo.phone || ""}
                    />
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <legend className="legender">Your Password</legend>
                <div className="form-group ">
                  <label htmlFor="input-first Name" className="col-sm-2 ">
                    Password
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="password"
                      name="password"
                      placeholder="Password"
                      id="input-password"
                      className="from-control"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group ">
                  <label htmlFor="input-first Name" className="col-sm-2">
                    Password Confirm
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      name="lastname"
                      placeholder="Password Confirm"
                      id="input-firstname"
                      className="from-control"
                    />
                  </div>
                </div>
              </fieldset>
              <fieldset></fieldset>
            </form>
            <div className="">
              {" "}
              <label
                htmlFor="flexCheckChecked"
                className="radio-inline active"
                id="flexCheckChecked"
              >
                {" "}
                <input type="radio" name="newslatter" value={1} /> My delivery
                and billing addresses are the same.
              </label>
            </div>
            <label htmlFor="" className="radio-inline">
              {" "}
              <input type="radio" name="newslatter" value={0} /> I wish to
              subscribe to the Faiza Inc newsletter.
            </label>
          </div>
          <div className="col-md-6">
            <form action="">
              <fieldset id="accounts">
                <legend className="legender">Your Address</legend>
                <div className="form-group ">
                  <label htmlFor="input-first Name" className="col-sm-2 ">
                    Company
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      name="firstname"
                      placeholder="Company"
                      id="input-firstname"
                      className="from-control"
                      value={userInfo.address || ""}
                    />
                  </div>
                </div>
                <div className="form-group ">
                  <label htmlFor="input-first Name" className="col-sm-2 ">
                    Address 1
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      name="lastname"
                      placeholder="Address 1"
                      id="input-firstname"
                      className="from-control"
                      value={userInfo.address || ""}
                    />
                  </div>
                </div>
                <div className="form-group ">
                  <label htmlFor="input-first Name" className="col-sm-2 ">
                    Address 2
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      name="email"
                      placeholder="Address 2"
                      id="input-firstname"
                      className="from-control"
                      value={userInfo.address || ""}
                    />
                  </div>
                </div>
                <div className="form-group ">
                  <label htmlFor="input-first Name" className="col-sm-2 ">
                    City
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      name="firstname"
                      placeholder="City"
                      id="input-telephone"
                      className="from-control"
                      value={userInfo.city || ""}
                    />
                  </div>
                </div>
                <div className="form-group ">
                  <label htmlFor="input-first Name" className="col-sm-2 ">
                    Post Code
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      name="firstname"
                      placeholder="Post Code"
                      id="input-telephone"
                      className="from-control"
                      value={userInfo.zip || ""}
                    />
                  </div>
                </div>
                <div className="form-group ">
                  <label htmlFor="input-first Name" className="col-sm-2 ">
                    Country
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      name="firstname"
                      placeholder="Country"
                      id="input-telephone"
                      className="from-control"
                      value={userInfo.country || ""}
                    />
                  </div>
                </div>
                <div className="form-group ">
                  <label htmlFor="input-first Name" className="col-sm-2 ">
                    Region / State
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      name="firstname"
                      placeholder="Region / State"
                      id="input-telephone"
                      className="from-control"
                      value={userInfo.city || ""}
                    />
                  </div>
                </div>
                <div className="buttons">
                  <div className="pull-right">
                    I have read and agree to the
                    <a href="#" className="agree">
                      <b> Privacy Policy </b>
                    </a>
                    <input type="checkbox" name="agree" value={1} />
                    <button
                      // type="submit"
                      // value={"continue"}
                      onClick={(e) => {
                        e.preventDefault();
                        if (!userName.firstName.length) {
                          return;
                        }
                        onFirstContinueClickHandler("1");
                      }}
                      className="btn btn-primary"
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      ),
    },

    {
      num: "2",
      title: "Step 3: Delivery Details",
      innerComponent: (
        <div className="container">
          <p>Enter your destination to get a shipping estimate</p>
          <div className="row pb-3">
            <div className="col-md-2">
              <p>First Name</p>
            </div>
            <div className="col-md-10 d-flex justify-content-center align-items-center">
              <input
                className="shipping"
                placeholder="Enter your first name"
                type="text"
                value={userInfo.name.split(" ")[0] || ""}
              />
              {/* <select
                className="shipping"
                name="shippingCountry"
                id="shippingCountry"
              >
                Shipping Country
                <option value="japan">Japan</option>
                <option value="france">France</option>
                <option value="bangladesh">Bangladesh</option>
                <option value="india">India</option>
              </select> */}
            </div>
          </div>
          <div className="row pb-3">
            <div className="col-md-2">
              <p>Last Name</p>
            </div>
            <div className="col-md-10 d-flex justify-content-center align-items-center">
              <input
                className="shipping"
                placeholder="Enter your last name"
                type="text"
                value={userInfo.name.split(" ")[1] || ""}
              />
              {/* <select
                className="shipping"
                name="shippingRegion"
                id="shippingRegion"
              >
                Shipping Region
                <option value="japan">Japan</option>
                <option value="france">France</option>
                <option value="bangladesh">Bangladesh</option>
                <option value="india">India</option>
              </select> */}
            </div>
          </div>
          <div className="row pb-3">
            <div className="col-md-2">
              <p>Email</p>
            </div>
            <div className="col-md-10 d-flex justify-content-center align-items-center">
              <input
                className="shipping"
                placeholder="Enter email address"
                type="email"
                value={userInfo.email || ""}
              />
            </div>
          </div>

          <div className="row pb-3">
            <div className="col-md-2">
              <p>Address 1</p>
            </div>
            <div className="col-md-10 d-flex justify-content-center align-items-center">
              <input className="shipping" placeholder="Address 1" type="text" value={userInfo.address || ""} />
            </div>
          </div>

          <div className="row pb-3">
            <div className="col-md-2">
              <p>Address 2</p>
            </div>
            <div className="col-md-10 d-flex justify-content-center align-items-center">
              <input className="shipping" placeholder="Address 2" type="text" value={userInfo.address || ""} />
            </div>
          </div>

          <div className="row pb-3">
            <div className="col-md-2">
              <p>City</p>
            </div>
            <div className="col-md-10 d-flex justify-content-center align-items-center">
              <input className="shipping" placeholder="Dhaka" type="text" value={userInfo.city || ""} />
            </div>
          </div>

          <div className="row pb-3">
            <div className="col-md-2">
              <p>Post Code</p>
            </div>
            <div className="col-md-10 d-flex justify-content-center align-items-center">
              <input className="shipping" placeholder="1207" type="text" value={userInfo.zip || ""} />
            </div>
          </div>

          <div className="row pb-3">
            <div className="col-md-2">
              <p>Country</p>
            </div>
            <div className="col-md-10 d-flex justify-content-center align-items-center">
              {/* <input
                className="shipping"
                placeholder="Enter your last name"
                type="text"
              /> */}
              <select
                className="shipping"
                name="shippingRegion"
                id="shippingCountry"
                value={userInfo.country || null}
              >
                Shipping Region
                <option value="japan">Japan</option>
                <option value="france">France</option>
                <option value="Bangladesh">Bangladesh</option>
                <option value="india">India</option>
              </select>
            </div>
          </div>

          <div className="row pb-3">
            <div className="col-md-2">
              <p>Region / State</p>
            </div>
            <div className="col-md-10 d-flex justify-content-center align-items-center">
              <select
                className="shipping"
                name="shippingRegion"
                id="shippingState"
                value={userInfo.country || null}
              >
                Shipping Region
                <option value="Bangladesh">Bangladesh</option>
                <option value="france">India</option>
                <option value="bangladesh">USA</option>
                <option value="india">Kyoto</option>
              </select>
            </div>
          </div>
          <div className="w-100 d-flex justify-content-end align-items-center">
            <button
              onClick={() => onFirstContinueClickHandler("2")}
              className="getQuotesBtn"
            >
              Continue
            </button>
          </div>
        </div>
      ),
    },

    {
      num: "3",
      title: "Step 4: Payment Method",
      innerComponent: (
        <div className="row">
          <div className="col-md-6">
            <p>Please select the preferred payment method.</p>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                checked
              />
              <label class="form-check-label" for="flexRadioDefault2">
                Cash On Delivery
              </label>
            </div>
          </div>
          <div className="w-100 d-flex justify-content-end align-items-center">
            <button
              onClick={() => onFirstContinueClickHandler("3")}
              className="getQuotesBtn"
            >
              Continue
            </button>
          </div>
        </div>
      ),
    },

    {
      num: "4",
      title: "Step 5: Confirm Order",
      innerComponent: (
        <div class="container">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Product Name</th>
                <th>Vendor Name</th>
                <th>Quantity</th>
                <th>Unit Price </th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {cartItems.length &&
                cartItems.map((product) => (
                  <tr>
                    <td>{product.name}</td>
                    <td>{product.vendor_name}</td>
                    <td>{product.quantity}</td>
                    <td>৳{numberWithCommas(product.price)}</td>
                    <td>
                      ৳
                      {numberWithCommas(
                        product.price * product.quantity
                      )}
                    </td>
                  </tr>
                ))}
              <tr>
                <td align="right" colSpan={4}>
                  Sub-total:
                </td>
                <td>৳{subtotalPrice}</td>
              </tr>
              {/* <tr>
                <td align="right" colSpan={4}>
                  Flat Shipping Rate:
                </td>
                <td>৳0</td>
              </tr> */}
              <tr>
                <td align="right" colSpan={4}>
                  Total:
                </td>
                <td>৳{+subtotalPrice + 0}</td>
              </tr>
            </tbody>
          </table>

          <div className="w-100 d-flex justify-content-end align-items-center">
            <button
              onClick={() => {
                onFirstContinueClickHandler("4");
                onOrderSubmitHandler();
              }}
              className="getQuotesBtn"
            >
              Confirm Order
            </button>
          </div>
        </div>
      ),
    },
  ];

  if (isAuthenticated) {
     accordArray = [
      {
        num: "0",
        title: "Step 1: Billing Details",
        innerComponent: (
          <div className="row">
            <div className="col-md-6">
              <form action="">
                <fieldset id="accounts">
                  <legend className="legender">Your Personal Details</legend>
                  <div className="form-group ">
                    <label htmlFor="input-first Name" className="col-sm-2 ">
                      First Name
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        name="firstname"
                        placeholder="First Name"
                        id="input-firstname"
                        className="from-control"
                        value={userName.firstName}
                        onChange={(e) =>
                          setUserName({ ...userName, firstName: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group ">
                    <label htmlFor="input-first Name" className="col-sm-2 ">
                      Last Name
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        name="lastname"
                        placeholder="Last Name"
                        id="input-firstname"
                        className="from-control"
                        value={userName.lastName}
                        onChange={(e) =>
                          setUserName({ ...userName, lastName: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group ">
                    <label htmlFor="input-first Name" className="col-sm-2 ">
                      E-Mail
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        name="email"
                        placeholder="E-Mail"
                        id="input-email"
                        className="from-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form-group ">
                    <label htmlFor="input-first Name" className="col-sm-2 ">
                      Telephone
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        name="firstname"
                        placeholder="Telephone"
                        id="input-telephone"
                        className="from-control"
                        value={userInfo.phone || ""}
                      />
                    </div>
                  </div>
                </fieldset>
                <fieldset>
                  <legend className="legender">Your Password</legend>
                  <div className="form-group ">
                    <label htmlFor="input-first Name" className="col-sm-2 ">
                      Password
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="password"
                        name="password"
                        placeholder="Password"
                        id="input-password"
                        className="from-control"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form-group ">
                    <label htmlFor="input-first Name" className="col-sm-2">
                      Password Confirm
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        name="lastname"
                        placeholder="Password Confirm"
                        id="input-firstname"
                        className="from-control"
                      />
                    </div>
                  </div>
                </fieldset>
                <fieldset></fieldset>
              </form>
              <div className="">
                {" "}
                <label
                  htmlFor="flexCheckChecked"
                  className="radio-inline active"
                  id="flexCheckChecked"
                >
                  {" "}
                  <input type="radio" name="newslatter" value={1} /> My delivery
                  and billing addresses are the same.
                </label>
              </div>
              <label htmlFor="" className="radio-inline">
                {" "}
                <input type="radio" name="newslatter" value={0} /> I wish to
                subscribe to the Faiza Inc newsletter.
              </label>
            </div>
            <div className="col-md-6">
              <form action="">
                <fieldset id="accounts">
                  <legend className="legender">Your Address</legend>
                  <div className="form-group ">
                    <label htmlFor="input-first Name" className="col-sm-2 ">
                      Company
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        name="firstname"
                        placeholder="Company"
                        id="input-firstname"
                        className="from-control"
                        value={userInfo.address || ""}
                      />
                    </div>
                  </div>
                  <div className="form-group ">
                    <label htmlFor="input-first Name" className="col-sm-2 ">
                      Address 1
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        name="lastname"
                        placeholder="Address 1"
                        id="input-firstname"
                        className="from-control"
                        value={userInfo.address || ""}
                      />
                    </div>
                  </div>
                  <div className="form-group ">
                    <label htmlFor="input-first Name" className="col-sm-2 ">
                      Address 2
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        name="email"
                        placeholder="Address 2"
                        id="input-firstname"
                        className="from-control"
                        value={userInfo.address || ""}
                      />
                    </div>
                  </div>
                  <div className="form-group ">
                    <label htmlFor="input-first Name" className="col-sm-2 ">
                      City
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        name="firstname"
                        placeholder="City"
                        id="input-telephone"
                        className="from-control"
                        value={userInfo.city || ""}
                      />
                    </div>
                  </div>
                  <div className="form-group ">
                    <label htmlFor="input-first Name" className="col-sm-2 ">
                      Post Code
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        name="firstname"
                        placeholder="Post Code"
                        id="input-telephone"
                        className="from-control"
                        value={userInfo.zip || ""}
                      />
                    </div>
                  </div>
                  <div className="form-group ">
                    <label htmlFor="input-first Name" className="col-sm-2 ">
                      Country
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        name="firstname"
                        placeholder="Country"
                        id="input-telephone"
                        className="from-control"
                        value={userInfo.country || ""}
                      />
                    </div>
                  </div>
                  <div className="form-group ">
                    <label htmlFor="input-first Name" className="col-sm-2 ">
                      Region / State
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        name="firstname"
                        placeholder="Region / State"
                        id="input-telephone"
                        className="from-control"
                        value={userInfo.city || ""}
                      />
                    </div>
                  </div>
                  <div className="buttons">
                    <div className="pull-right">
                      I have read and agree to the
                      <a href="#" className="agree">
                        <b> Privacy Policy </b>
                      </a>
                      <input type="checkbox" name="agree" value={1} />
                      <button
                        // type="submit"
                        // value={"continue"}
                        onClick={(e) => {
                          e.preventDefault();
                          if (!userName.firstName.length) {
                            return;
                          }
                          onFirstContinueClickHandler("0");
                        }}
                        className="btn btn-primary"
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        ),
      },

      {
        num: "1",
        title: "Step 2: Delivery Details",
        innerComponent: (
          <div className="container">
            <p>Enter your destination to get a shipping estimate</p>
            <div className="row pb-3">
              <div className="col-md-2">
                <p>First Name</p>
              </div>
              <div className="col-md-10 d-flex justify-content-center align-items-center">
                <input
                  className="shipping"
                  placeholder="Enter your first name"
                  type="text"
                  value={userInfo.name.split(" ")[0] || ""}
                />
                {/* <select
                  className="shipping"
                  name="shippingCountry"
                  id="shippingCountry"
                >
                  Shipping Country
                  <option value="japan">Japan</option>
                  <option value="france">France</option>
                  <option value="bangladesh">Bangladesh</option>
                  <option value="india">India</option>
                </select> */}
              </div>
            </div>
            <div className="row pb-3">
              <div className="col-md-2">
                <p>Last Name</p>
              </div>
              <div className="col-md-10 d-flex justify-content-center align-items-center">
                <input
                  className="shipping"
                  placeholder="Enter your last name"
                  type="text"
                  value={userInfo.name.split(" ")[1] || ""}
                />
                {/* <select
                  className="shipping"
                  name="shippingRegion"
                  id="shippingRegion"
                >
                  Shipping Region
                  <option value="japan">Japan</option>
                  <option value="france">France</option>
                  <option value="bangladesh">Bangladesh</option>
                  <option value="india">India</option>
                </select> */}
              </div>
            </div>
            <div className="row pb-3">
              <div className="col-md-2">
                <p>Email</p>
              </div>
              <div className="col-md-10 d-flex justify-content-center align-items-center">
                <input
                  className="shipping"
                  placeholder="Enter email address"
                  type="email"
                  value={userInfo.email || ""}
                />
              </div>
            </div>

            <div className="row pb-3">
              <div className="col-md-2">
                <p>Address 1</p>
              </div>
              <div className="col-md-10 d-flex justify-content-center align-items-center">
                <input className="shipping" placeholder="Address 1" type="text" value={userInfo.address || ""} />
              </div>
            </div>

            <div className="row pb-3">
              <div className="col-md-2">
                <p>Address 2</p>
              </div>
              <div className="col-md-10 d-flex justify-content-center align-items-center">
                <input className="shipping" placeholder="Address 2" type="text" value={userInfo.address || ""} />
              </div>
            </div>

            <div className="row pb-3">
              <div className="col-md-2">
                <p>City</p>
              </div>
              <div className="col-md-10 d-flex justify-content-center align-items-center">
                <input className="shipping" placeholder="Dhaka" type="text" value={userInfo.city || ""} />
              </div>
            </div>

            <div className="row pb-3">
              <div className="col-md-2">
                <p>Post Code</p>
              </div>
              <div className="col-md-10 d-flex justify-content-center align-items-center">
                <input className="shipping" placeholder="1207" type="text" value={userInfo.zip || ""} />
              </div>
            </div>

            <div className="row pb-3">
              <div className="col-md-2">
                <p>Country</p>
              </div>
              <div className="col-md-10 d-flex justify-content-center align-items-center">
                {/* <input
                  className="shipping"
                  placeholder="Enter your last name"
                  type="text"
                /> */}
                <select
                  className="shipping"
                  name="shippingRegion"
                  id="shippingCountry"
                  value={userInfo.country || null}
                >
                  Shipping Region
                  <option value="japan">Japan</option>
                  <option value="france">France</option>
                  <option value="Bangladesh">Bangladesh</option>
                  <option value="india">India</option>
                </select>
              </div>
            </div>

            <div className="row pb-3">
              <div className="col-md-2">
                <p>Region / State</p>
              </div>
              <div className="col-md-10 d-flex justify-content-center align-items-center">
                <select
                  className="shipping"
                  name="shippingRegion"
                  id="shippingState"
                  value={userInfo.country || null}
                >
                  Shipping Region
                  <option value="Bangladesh">Bangladesh</option>
                  <option value="france">India</option>
                  <option value="bangladesh">USA</option>
                  <option value="india">Kyoto</option>
                </select>
              </div>
            </div>
            <div className="w-100 d-flex justify-content-end align-items-center">
              <button
                onClick={() => onFirstContinueClickHandler("1")}
                className="getQuotesBtn"
              >
                Continue
              </button>
            </div>
          </div>
        ),
      },

      {
        num: "2",
        title: "Step 3: Payment Method",
        innerComponent: (
          <div className="row">
            <div className="col-md-6">
              <p>Please select the preferred payment method.</p>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  checked
                />
                <label class="form-check-label" for="flexRadioDefault2">
                  Cash On Delivery
                </label>
              </div>
            </div>
            <div className="w-100 d-flex justify-content-end align-items-center">
              <button
                onClick={() => onFirstContinueClickHandler("2")}
                className="getQuotesBtn"
              >
                Continue
              </button>
            </div>
          </div>
        ),
      },

      {
        num: "3",
        title: "Step 4: Confirm Order",
        innerComponent: (
          <div class="container">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th>Vendor Name</th>
                  <th>Quantity</th>
                  <th>Unit Price </th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {cartItems.length &&
                  cartItems.map((product) => (
                    <tr>
                      <td>{product.name}</td>
                      <td>{product.vendor_name}</td>
                      <td>{product.quantity}</td>
                      <td>৳{numberWithCommas(product.price)}</td>
                      <td>
                        ৳
                        {numberWithCommas(
                          product.price * product.quantity
                        )}
                      </td>
                    </tr>
                  ))}
                <tr>
                  <td align="right" colSpan={4}>
                    Sub-total:
                  </td>
                  <td>৳{subtotalPrice}</td>
                </tr>
                {/* <tr>
                  <td align="right" colSpan={4}>
                    Flat Shipping Rate:
                  </td>
                  <td>৳0</td>
                </tr> */}
                <tr>
                  <td align="right" colSpan={4}>
                    Total:
                  </td>
                  <td>৳{+subtotalPrice + 0}</td>
                </tr>
              </tbody>
            </table>

            <div className="w-100 d-flex justify-content-end align-items-center">
              <button
                onClick={() => {
                  onFirstContinueClickHandler("3");
                  onOrderSubmitHandler();
                }}
                className="getQuotesBtn"
              >
                Confirm Order
              </button>
            </div>
          </div>
        ),
      },
    ];
  }

  return (
    <div>
      <CartBannerComponent title="Checkout" />
      <div className="container chkout">
        <div className="row">
          <h1>Checkout</h1>
          <Accordion defaultActiveKey="0">
            {accordArray.map(({ num, title, innerComponent }) => (
              <CardComponent
                passedStep={passedStep}
                eventKey={num}
                unref={
                  // num === "0"
                  //   ? ref0
                  //   : num === "1"
                  //     ? ref1
                  //     : num === "2"
                  //       ? ref2
                  //       : num === "3"
                  //         ? ref3
                  //         : num === "4"
                  //           ? ref4
                  //           // : num === "5"
                  //           // ? ref5
                  //           : ref0

                  isAuthenticated ? (
                    num === "0"
                      ? ref0
                      : num === "1"
                        ? ref1
                        : num === "2"
                          ? ref2
                          : num === "3"
                            ? ref3
                            // : num === "4"
                            //   ? ref4
                            // : num === "5"
                            // ? ref5
                            : ref0
                  ) : (num === "0"
                    ? ref0
                    : num === "1"
                      ? ref1
                      : num === "2"
                        ? ref2
                        : num === "3"
                          ? ref3
                          : num === "4"
                            ? ref4
                            // : num === "5"
                            // ? ref5
                            : ref0)
                }
                heading={title}
              >
                {innerComponent}
              </CardComponent>
            ))}
          </Accordion>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.cart,
    userInfo: state.auth.user,
    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = {
  clearCart: cartOperations.clearCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPage);
