import axios from "axios";
import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import "./Collops.css";


function CartAccordion({ cartItems, subTotal, user }) {
  const [show, setShow] = useState(false);

  const [email, setEmail] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (user.email) {
      handleEmailSubmit();
      return;
    } 
    setShow(true);
  };


  const handleEmailSubmit = () => {
    const quoteObj = {
      email: user.email || email,
      cartItems: cartItems,
      subtotal: subTotal,
      total: subTotal,
    };

    axios
      .post("https://faizainc-backend.techknowgram.net/api/quote", quoteObj)
      .then(function (response) {
        console.log(response);
        if (response.status === 200) {
          setEmail("");
          handleClose();
          alert("Quotation Generated Successfully, Please Check Email");
        }
      })
      .catch(function (error) {
        console.log(error);
        alert("Quotation Generation Failure");
      });
  };
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Quotation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoFocus
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEmailSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Use Coupon Code</Accordion.Header>
          <Accordion.Body>
            <div className="container">
              <div className="row">
                <div className="col-md-2">
                  <p>Enter you coupon here</p>
                </div>
                <div className="col-md-10 d-flex justify-content-center align-items-center">
                  <input
                    name="couponCode"
                    id="couponCode"
                    placeholder="Enter your coupon here"
                    type="text"
                  />
                  <button className="applyCoupon">APPLY COUPON</button>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="">
        <button onClick={handleShow} className="getQuotesBtn">
          Get Quotes
        </button>
      </div>
    </>
  );
};


const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = {}



export default connect(mapStateToProps, mapDispatchToProps)(CartAccordion);
